import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  InputGroup,
  ProgressBar,
  Row,
  Spinner,
} from "react-bootstrap";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { app } from "../../../../firebase";
import { Toaster } from "sonner";
import {
  Alert,
  brands,
  categories,
  colorCategories,
  imagesMapToUpload,
  sizes,
  useAddBalloonV2,
  useDeleteBalloonV2,
  useDeleteUploadedImagesStorage,
} from "../../../../utils";
import {
  AutoCompleteFilters,
  Inputs,
  ReusableForm,
  useForm,
} from "../../../../components";
import style from "./BalloonsForm.module.css";

const initialValues = {
  name: "",
  displayName: "",
  color: "",
  colorCategories: "none",
  brand: "none",
  combined: false,
  insideBrand: "",
  insideColor: "",
  outsideBrand: "",
  outsideColor: "",
  layers: "",
  image: null,
  category: "none",
  ...Object.fromEntries(
    sizes.map((size) => [`size${size.toString().replace(/\./g, "_")}`, false])
  ),
};

const db = getFirestore(app);

const BalloonsForm = ({ editItem, setShow, setMissingImage }) => {
  const { values, setValues, handleInputChange } = useForm(initialValues);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState("");
  const [editImage, setEditImage] = useState(true);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const addBalloon = useAddBalloonV2();
  const { mutate: deleteImagesFromStorage } = useDeleteUploadedImagesStorage();
  const deleteBalloon = useDeleteBalloonV2();

  useEffect(() => {
    if (editItem) {
      let objectArray;
      if (editItem.colorCategories) {
        objectArray = editItem.colorCategories.map((colorCat) => ({
          name: colorCat,
        }));
        setSelected(objectArray);
      }
      editItem.color = editItem.color.replace("#", "");
      setValues(editItem);
      setEditImage(false);
    }
  }, [editItem, setValues]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateSubmit()) {
      return;
    }
    setError("");
    setLoading(true);
    const balloonToSend = { ...values };

    balloonToSend.color = balloonToSend.color.toLowerCase();
    balloonToSend.colorCategories = selected.map((i) => i.name);
    if (!balloonToSend.color.includes("#")) {
      balloonToSend.color = "#" + balloonToSend.color;
    }

    try {
      const docRef = doc(db, "balloonsv2", balloonToSend.color);

      const docSnap = await getDoc(docRef);

      if (docSnap.exists() && !editItem) {
        Alert.error("There is already a balloon with this color");
        setLoading(false);
        return;
      }

      if (editImage) {
        if (values.image === null) {
          setError("You must upload an image");
          setLoading(false);
          return;
        } else {
          try {
            const imageWithURL = await imagesMapToUpload({
              images: values.image,
              setProgress,
              collection: "balloonsPrueba",
              id: balloonToSend.color,
            });
            balloonToSend.image = imageWithURL;
            setMissingImage(false);
          } catch (error) {
            Alert.error("Error processing image, try again");
            setLoading(false);
            return;
          }
        }
      }

      if (balloonToSend.combined) {
        balloonToSend.brand =
          balloonToSend.outsideBrand + "/" + balloonToSend.insideBrand;
        balloonToSend.layers =
          balloonToSend.outsideColor + "/" + balloonToSend.insideColor;
      }

      if (editItem) {
        balloonToSend.id = editItem.id;
        if (editItem.color !== balloonToSend.color) {
          deleteBalloon.mutate(`#${editItem.color}`);
        }
      }

      addBalloon.mutate({
        balloon: balloonToSend,
        balloonColor: balloonToSend.color,
      });
      setLoading(false);
      Alert.success("Submitted successfully");
      setTimeout(() => {
        setShow();
      }, 1000);
    } catch (error) {
      Alert.error("There was an error submitting");
      console.log(error);
      setLoading(false);
    }
  };

  const imagePathsToDelete = ({ images }) => {
    if (Array.isArray(images)) {
      return images.map((image) => {
        return deleteImagesFromStorage({
          imagePath: image.imagePath,
          imageURL: image.imageURL,
        });
      });
    } else if (typeof images === "string") {
      return deleteImagesFromStorage({
        imageURL: images,
      });
    }
  };

  const handleSetEditImage = () => {
    imagePathsToDelete({ images: editItem.image || editItem.imageURL });
    setValues({ ...values, image: null });
    setEditImage(true);
    setMissingImage(true);
  };

  const validateSubmit = () => {
    const validations = [
      { condition: values.name === "", message: "You must insert a name" },
      {
        condition: values.displayName === "",
        message: "You must insert a display name",
      },
      {
        condition: values.color === "",
        message: "You must insert a Hex color code",
      },
      {
        condition: values.color.length < 6 || values.color.length > 6,
        message: "You must insert a valid hex color code",
      },
      {
        condition: selected.length === 0,
        message: "You must select at least one color category",
      },
      {
        condition: values.category === "none",
        message: "You must select a category",
      },
      {
        condition: !values.combined && values.brand === "none",
        message: "You must insert a brand",
      },
      {
        condition: values.combined && values.outsideColor === "",
        message: "You must insert an outside color",
      },
      {
        condition: values.combined && values.outsideBrand === "",
        message: "You must insert an outside brand",
      },
      {
        condition: values.combined && values.insideColor === "",
        message: "You must insert an inside color",
      },
      {
        condition: values.combined && values.insideBrand === "",
        message: "You must select an inside brand",
      },
      {
        condition: !isAtLeastOneChecked(),
        message: "You must select at least one available size",
      },
      { condition: values.image === null, message: "You must upload an image" },
    ];

    for (let validation of validations) {
      if (validation.condition) {
        setError(validation.message);
        return false;
      }
    }

    return true;
  };

  const isAtLeastOneChecked = () => {
    return sizes.some(
      (size) => values[`size${size.toString().replace(/\./g, "_")}`] === true
    );
  };

  const handleCheckbox = (e) => {
    const { name, checked } = e.target;
    const nameFormated = name.replace(".", "_");
    setValues({ ...values, [nameFormated]: checked });
  };

  const handleImageChange = (e) => {
    setValues({ ...values, image: [e.target.files[0]] });
    setMissingImage(false);
  };

  return (
    <ReusableForm className={style.form} onSubmit={handleSubmit}>
      <Row>
        <Col>
          <Inputs.FormControl
            label="Display Name"
            name="displayName"
            value={values.displayName}
            onChange={handleInputChange}
            type="text"
          />
        </Col>
        <Col>
          <Inputs.FormControl
            label="Manufacturer's Name"
            name="name"
            value={values.name}
            onChange={handleInputChange}
            type="text"
          />
        </Col>
      </Row>
      <Row className="align-items-end">
        <Col>
          <Inputs.FormControl
            label="Hex Color Code"
            icon="#"
            name="color"
            value={values.color}
            onChange={handleInputChange}
            placeholder="F3F3F3"
            type="text"
            decoration={
              <span
                className={style.hexColorSelected}
                style={{ backgroundColor: `#${values.color}` }}
              ></span>
            }
          />
        </Col>
        <Col>
          <AutoCompleteFilters
            optionalTitle="Color categories"
            options={colorCategories}
            selected={selected}
            setSelected={setSelected}
            placeholder="Select a color category"
            renderOption={(option) => (
              <small className={style.optionTitle}>{option.name}</small>
            )}
          />
        </Col>
        <Col>
          <Inputs.Select
            label="Category"
            onChange={handleInputChange}
            value={values.category}
            name="category"
            options={categories}
          />
        </Col>
      </Row>
      <Row>
        {!values.combined && (
          <Col>
            <Form.Select
              label="Brand"
              onChange={handleInputChange}
              value={values.brand}
              name="brand"
            >
              <option>Select brand</option>
              {brands.map((brand, index) => (
                <option key={index} value={brand}>
                  {brand === "Ellies" ? "Ellie's" : brand}
                </option>
              ))}
            </Form.Select>
          </Col>
        )}
        <Col
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
            justifyContent: values.combined ? "start" : "center",
          }}
        >
          <Form.Label>Layered</Form.Label>
          <Form.Check
            name="combined"
            checked={values.combined}
            onChange={handleCheckbox}
            value={values.combined}
          />
        </Col>
        {values.combined && (
          <Row className="m-0 p-0">
            <Col className="mt-2">
              <h6 className={style.insideOutsideFormTitle}>OUTSIDE COLOR</h6>
              <InputGroup>
                <Form.Control
                  onChange={handleInputChange}
                  value={values.outsideColor}
                  name="outsideColor"
                  disabled={!values.combined}
                  placeholder="Color name"
                />
                <Form.Select
                  onChange={handleInputChange}
                  value={values.outsideBrand}
                  name="outsideBrand"
                  disabled={!values.combined}
                >
                  <option>Select brand</option>
                  {brands.map((brand, index) => (
                    <option key={index} value={brand}>
                      {brand === "Ellies" ? "Ellie's" : brand}
                    </option>
                  ))}
                </Form.Select>
              </InputGroup>
            </Col>
            <Col className="mt-2">
              <h6 className={style.insideOutsideFormTitle}>INSIDE COLOR</h6>
              <InputGroup>
                <Form.Control
                  onChange={handleInputChange}
                  value={values.insideColor}
                  name="insideColor"
                  disabled={!values.combined}
                  placeholder="Color name"
                />
                <Form.Select
                  onChange={handleInputChange}
                  name={values.combined ? "insideBrand" : "brand"}
                  value={values.combined ? values.insideBrand : values.brand}
                >
                  <option>Select brand</option>
                  {brands.map((brand, index) => (
                    <option key={index} value={brand}>
                      {brand === "Ellies" ? "Ellie's" : brand}
                    </option>
                  ))}
                </Form.Select>
              </InputGroup>
            </Col>
          </Row>
        )}
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>Available sizes</Form.Label>
            <Col /* style={{ display: "flex", justifyContent: "space-between" }} */
            >
              {sizes.map((size, index) => {
                const formattedSize = size.toString().replace(/\./g, "_");
                const baseSize = formattedSize.split("_")[0];
                return (
                  <Form.Check
                    key={index}
                    name={`size${formattedSize}`}
                    checked={values[`size${formattedSize}`] || false}
                    inline
                    label={
                      size.toString().includes(".")
                        ? `${baseSize}" LINK`
                        : size > 100
                        ? `${baseSize}`
                        : `${size}"`
                    }
                    type="checkbox"
                    onChange={handleCheckbox}
                  />
                );
              })}
            </Col>
          </Form.Group>
        </Col>
      </Row>
      {editImage ? (
        <Form.Group controlId="formFile" className="mb-3">
          <Form.Label>Balloon Image</Form.Label>
          <Form.Control type="file" onChange={handleImageChange} />
        </Form.Group>
      ) : (
        <Button onClick={handleSetEditImage}>Change image</Button>
      )}

      <span className={style.errorTxt}>{error}</span>

      {progress > 0 && (
        <ProgressBar
          min={0}
          now={progress}
          max={100}
          striped
          animated
          label={progress === 100 ? "Complete" : `${progress}%`}
          variant={progress === 100 && "success"}
        />
      )}
      <Button className={style.submitBtn} type="submit">
        Submit to database
        {loading && (
          <>
            <span> </span>
            <Spinner size="sm" animation="grow" />
          </>
        )}
      </Button>
      <Toaster richColors position="bottom-center" />
    </ReusableForm>
  );
};

export default BalloonsForm;
