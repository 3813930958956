import React from "react";
import { ListGroup } from "react-bootstrap";
import { FiDownload, FiShare } from "react-icons/fi";
import { useAppContext } from "../../../../context";
import { BottomSheet } from "../../../index";
import style from "../MobileCommands.module.css";

const MoreBottomSheet = ({ showBottomSheet, setShowBottomSheet, setShow }) => {
  const { colormatch } = useAppContext();
  return (
    <BottomSheet
      open={showBottomSheet}
      setOpen={() =>
        setShowBottomSheet((prevState) => ({
          ...prevState,
          more: false,
        }))
      }
    >
      <ListGroup>
        <ListGroup.Item
          action
          disabled={colormatch.length < 2}
          className={style.mainOption}
          onClick={() => {
            setShow({ download: true });
            setShowBottomSheet((prevState) => ({
              ...prevState,
              more: false,
            }));
          }}
        >
          <FiDownload size="1.5rem" /> Download
        </ListGroup.Item>
        <ListGroup.Item
          action
          disabled={colormatch.length < 2}
          className={style.mainOption}
          onClick={() => {
            setShow({ share: true });
            setShowBottomSheet((prevState) => ({
              ...prevState,
              more: false,
            }));
          }}
        >
          <FiShare size="1.5rem" />
          Share
        </ListGroup.Item>
      </ListGroup>
    </BottomSheet>
  );
};

export default MoreBottomSheet;
