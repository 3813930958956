import React from "react";
import { Container } from "react-bootstrap";
import { Toaster } from "sonner";
import { MatchingTool } from "./index";

const Tool = () => {
  return (
    <Container fluid className="p-0 m-0 mb-3">
      <MatchingTool />
      {/* <Toaster richColors position="bottom-center" /> */}
    </Container>
  );
};

export default Tool;
