import React from "react";
import { Button } from "react-bootstrap";
import style from "./Share.module.css";

const CopyLinkButton = ({ functionOnClick, children }) => {
  return (
    <Button className={style.copyLinkBtn} onClick={functionOnClick}>
      {children}
    </Button>
  );
};

export default CopyLinkButton;
