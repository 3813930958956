import React from "react";
import { ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { RiUploadCloudLine } from "react-icons/ri";
import { AiOutlineFolderOpen } from "react-icons/ai";
import { addDropdownOptions } from "../../../../utils";
import { BottomSheet } from "../../../index";
import style from "../MobileCommands.module.css";

const AddBottomSheet = ({
  showBottomSheet,
  setShowBottomSheet,
  handleOptionClick,
}) => {
  const { t } = useTranslation();
  return (
    <BottomSheet
      open={showBottomSheet}
      setOpen={() =>
        setShowBottomSheet((prevState) => ({
          ...prevState,
          add: false,
        }))
      }
    >
      <ListGroup>
        <ListGroup.Item className={style.mainOption}>
          <AiOutlineFolderOpen size="1.5rem" /> Insert Balloon from Library...
        </ListGroup.Item>
        {addDropdownOptions.map((option, index) => (
          option.label !== 'Confetti' && option.label !== 'Tassels' && option.label!== 'Add your own' &&(
            <ListGroup.Item
              key={index}
              action
              onClick={() => {
                handleOptionClick(option.key, option.label);
                setShowBottomSheet((prevState) => ({
                  ...prevState,
                  add: false,
                }));
              }}
              className={
                option.label === "Add your own" ? style.mainOption : style.options
              }
            >
              {option.label === "Add your own" && (
                <RiUploadCloudLine size="1.5rem" />
              )}{" "}
              {t(option.label)}
            </ListGroup.Item>
          )
        ))}
      </ListGroup>
    </BottomSheet>
  );
};

export default AddBottomSheet;
