import React from "react";
import { BottomSheet as SpringBottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
// import style from "./BottomSheet.module.css";

const BottomSheet = ({ open, setOpen, children }) => {
  return (
    <SpringBottomSheet
      // className={style.bottomSheetContainer}
      open={open}
      onDismiss={setOpen}
    >
      {children}
    </SpringBottomSheet>
  );
};

export default BottomSheet;
