import React, { createContext, useContext, useState } from "react";

const AppContext = createContext({
  colormatch: [],
  setColormatch: () => {},
  user: null,
  setUser: () => {},
  images: [],
  setImages: () => {},
  loading: true,
  setLoading: () => {},
  defaultModal: "",
  setDefaultModal: () => {},
  shareMode: "",
  setShareMode: () => {},
  createNewPaletteModal: false,
  setCreateNewPaletteModal: () => {},
});

export const AppWrapper = ({ children }) => {
  const [colormatch, setColormatch] = useState([]);
  const [user, setUser] = useState(null);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [defaultModal, setDefaultModal] = useState("");
  const [shareMode, setShareMode] = useState("");
  const [createNewPaletteModal, setCreateNewPaletteModal] = useState(false);

  return (
    <AppContext.Provider
      value={{
        colormatch,
        setColormatch,
        user,
        setUser,
        images,
        setImages,
        loading,
        setLoading,
        defaultModal,
        setDefaultModal,
        shareMode,
        setShareMode,
        createNewPaletteModal,
        setCreateNewPaletteModal,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppContext);
};
