import React from "react";
import {
  Button,
  Col,
  Image,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import style from "../CardsContainer.module.css";
import { useScreenSize } from "../../../utils";

const ViewDetailsRow = ({
  item,
  index,
  sizes,
  addColor,
  replaceColors,
  buttons,
  closer,
  autocomplete
  // shared,
}) => {
  const { t } = useTranslation();
  const screenSize = useScreenSize();
  return (
    <Row className={`${style.cardRow} ${autocomplete && style.autocompleteCardRow}`}>
      <Col xs={3} lg={1} className="m-0 p-0">
        <Image
          src={item.image ? item.image[0].imageURL : item.imageURL}
          style={{ aspectRatio: "4/5" }}
          width="100%"
          alt="balloonImg"
        />
        <p className={style.displayName}>{item.displayName || "-"}</p>
      </Col>
      <Col xs={9} lg={11}>
        <Row className="justify-content-center">
          {/* <Col xs={5} md={4} lg={1}>
            <small>{item.color}</small>
            <div style={{backgroundColor: item.color, width: '50px', height: '20px'}}></div>
            </Col> */}
          <Col xs={12} md={4} lg={5} className={`${screenSize >= 992 && 'ms-1'}`}>
            <h3 className={style.tableTitle}>{t("BRAND & COLOR NAME")}</h3>
            {item.combined ? (
              <>
                <h5 className={style.secondaryTitle}>{t("OUTSIDE/INSIDE")}</h5>
                <p className={style.dataBrandColorName}>
                  {item.brand.split("/")[0]} - {item.outsideColor} /{" "}
                  {item.brand.split("/")[1]} - {item.insideColor}
                </p>
              </>
            ) : (
              <>
                <p className={style.dataBrandColorName}>
                  {item.brand} - {item.name}
                </p>
              </>
            )}
            <small>{item.distance}</small>
          </Col>
          <Col xs={12} md={4} lg={3}>
            <h3 className={style.tableTitle}>{t("AVAILABLE SIZES")}</h3>
            <Row style={{paddingLeft: '.3rem'}}>
              {sizes.map((size) => {
                const formattedSize = size.toString().replace(/\./g, "_");
                const baseSize = formattedSize.split("_")[0];
                return (
                  item[`size${formattedSize}`] && (
                    <Col key={formattedSize} xs={"auto"} className={`${screenSize <= 992 ? style.sizesMobile : style.sizesDesktop}`}>
                      <h3 className={style.dataSizes}>
                        {size.toString().includes(".")
                          ? `${baseSize}" LINK`
                          : size > 100
                          ? `${baseSize}`
                          : `${size}"`}
                      </h3>
                    </Col>
                  )
                );
              })}
            </Row>
          </Col>
            <>
            <Col xs={12} lg={3} className={`${style.rowBtn} ${screenSize >= 992 && 'ms-4 mt-5 align-items-end'}`}>
              {index > 0 && buttons || index === 0 && !closer && buttons ? (
                <>
                  <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className={style.infoTooltip} style={{zIndex: '200000'}}>
                      {t("Replace with this balloon")}
                    </Tooltip>
                  }
                  
                  >
                    <Button
                      variant="dark"
                      size="sm"
                      onClick={() => replaceColors(item)}
                      className={style.actionBtn}
                      style={screenSize >= 992 ? {width: '130px'}:{}}
                    >
                      {t("Replace")}
                    </Button>
                  </OverlayTrigger>

                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip className={style.infoTooltip} style={{zIndex: '200000'}}>
                        {t("Add this balloon to palette")}
                      </Tooltip>
                    }
                  >
                    <Button
                      variant="dark"
                      size="sm"
                      onClick={() => addColor(item)}
                      className={style.actionBtn}
                      style={screenSize >= 992 ? {width: '130px'}:{}}
                    >
                      {t("Add to Palette")}
                    </Button>
                  </OverlayTrigger>
                </>
                ) : null }
              </Col>
            </>
        </Row>
      </Col>
    </Row>
  );
};

export default ViewDetailsRow;
