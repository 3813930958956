import React from "react";
import { useTranslation } from "react-i18next";
import { FiDownload } from "react-icons/fi";
import { Button, Popover, OverlayTrigger } from "react-bootstrap";
import { useAppContext } from "../../../context";
import { DownloadContent } from "./index";
import style from "./Download.module.css";

const DownloadBtn = ({ overlay, setOverlay }) => {
  const { t } = useTranslation();
  const { colormatch } = useAppContext();

  const popover = (
    <Popover id="popover-share">
      <Popover.Body>
        <DownloadContent window={overlay} />
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom"
      overlay={popover}
      containerPadding={20}
      rootClose
      onExit={() => setOverlay({ download: false })}
    >
      <Button
        variant="secondary"
        className={`${style.actionBtn} ${overlay && style.onTopBtn}`}
        disabled={colormatch.length > 1 ? false : true}
        onClick={() => setOverlay({ download: true })}
      >
        <FiDownload />
        {t("Download")}
      </Button>
    </OverlayTrigger>
  );
};

export default DownloadBtn;
