import { useMutation, useQueryClient } from "react-query";
import { updateDoc, getFirestore, doc } from "firebase/firestore";
import { app } from "../../firebase";

const db = getFirestore(app);

/* Welcome popup */
const updateFirstToolVisit = async (email) => {
  await updateDoc(doc(db, "usuarios", email), {
    firstToolVisit: false,
  });
};

export const useUpdateFirstToolVisit = () => {
  const queryClient = useQueryClient();
  return useMutation(updateFirstToolVisit, {
    onSuccess: () => {
      queryClient.invalidateQueries("usuarios");
    },
  });
};


/* Insert from library tooltip */
const updateFirstShowLibraryHelpTooltip = async (email) => {
  await updateDoc(doc(db, "usuarios", email), {
    firstShowLibraryHelpTooltip: false,
  });
};

export const useUpdateFirstShowLibraryHelpTooltip = () => {
  const queryClient = useQueryClient();
  return useMutation(updateFirstShowLibraryHelpTooltip, {
    onSuccess: () => {
      queryClient.invalidateQueries("usuarios");
    },
  });
};

/* Matching mobile tooltip */
const updateFirstShowMatchingMobileHelpTooltip = async (email) => {
  await updateDoc(doc(db, "usuarios", email), {
    firstShowMatchingMobileHelpTooltip: false,
  });
};

export const useUpdateFirstShowMatchingMobileHelpTooltip = () => {
  const queryClient = useQueryClient();
  return useMutation(updateFirstShowMatchingMobileHelpTooltip, {
    onSuccess: () => {
      queryClient.invalidateQueries("usuarios");
    },
  });
};

