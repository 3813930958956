import React, { useState, useEffect } from "react";
import style from "./CanvasWithZoom.module.css";
import { useScreenSize } from "../../utils";

const ZoomCanvas = ({
  zoomRef,
  ratio,
  border,
  showZoom,
  origCanvasRef,
  zoomInitialRender,
  mousePositions,
}) => {
  const [cursorX, setCursorX] = useState(0);
  const [cursorY, setCursorY] = useState(0);
  const screenSize = useScreenSize();

  /*   useEffect(()=>{
    if(mousePositions){
      setCursorX(mousePositions.x);
      setCursorY(mousePositions.y);
    }
  }, [mousePositions]); */

  useEffect(() => {
    // Verificar si el canvas de la imagen está disponible
    //console.log(origCanvasRef);
    setTimeout(() => {
      if (origCanvasRef && screenSize <= 992 && zoomInitialRender) {
        const { width, height, left, top } =
          origCanvasRef.getBoundingClientRect();

        // Establecer las posiciones iniciales en el centro del canvas de la imagen
        setCursorX(left + width / 2);
        setCursorY(top + height / 2);
      }
    }, 1000);
  }, [origCanvasRef, screenSize, zoomInitialRender]);

  /* console.log("x: ", cursorX)
  console.log("y: ", cursorY) */

  /* useEffect(() => {
    const preventScroll = (e) => {
      if (showZoom) {
        e.preventDefault(); // Previene el scroll
      }
    };

    window.addEventListener("touchmove", preventScroll, { passive: false });

    return () => {
      window.removeEventListener("touchmove", preventScroll);
    };
  }, [showZoom]); */

  useEffect(() => {
    let animationFrame;

    const handleMouseMove = (e) => {
      animationFrame = requestAnimationFrame(() => {
        setCursorX(e.clientX);
        setCursorY(e.clientY);
      });
    };

    const handleTouchMove = (e) => {
      animationFrame = requestAnimationFrame(() => {
        setCursorX(e.touches[0].clientX);
        setCursorY(e.touches[0].clientY);
      });
    };

    // Agregar event listeners para mouse y touch
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("touchmove", handleTouchMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("touchmove", handleTouchMove);
      cancelAnimationFrame(animationFrame);
    };
  }, []);

  return (
    <div
      className={style.zoomCircle}
      style={{
        position: "fixed", // Asegúrate de que el cursor siga al ratón de manera absoluta
        /* left: `${screenSize >= 992 ? cursorX - 30 : cursorX - 50}px`,
        top: `${screenSize >= 992 ? cursorY - 30 : cursorY - 140}px`, */
        
        /* Position 0,0. Zoom por debajo del dedo al arrastrar */
        left: `${screenSize >= 992 ? cursorX - 30 : cursorX - 45}px`,
        top: `${screenSize >= 992 ? cursorY - 30 : cursorY - 45}px`,
        width: ratio,
        height: ratio,
        border: `2px solid ${border}`,
        borderRadius: "100%",
        overflow: "hidden",
        zIndex: 10,
      }}
    >
      <canvas
        ref={zoomRef}
        alt="Zoom"
        style={{
          width: "100%", // El canvas ocupa el 100% del div padre
          height: "100%", // El canvas ocupa el 100% del div padre
          borderRadius: "50%",
          border: "solid 1px #292929",
        }}
        width={400} // Establece la resolución interna del canvas
        height={400} // Ajusta según sea necesario
      />
    </div>
  );
};

export default ZoomCanvas;
