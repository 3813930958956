import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ListGroup, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { serverTimestamp } from "firebase/firestore";
import {
  Alert,
  imagesMapToUpload,
  useDeleteUploadedImagesStorage,
  usePaletteById,
  useUpdatePalette,
} from "../../../../utils";
import { useAppContext } from "../../../../context";
import { BottomSheet } from "../../../index";
import style from "../MobileCommands.module.css";

const SaveBottomSheet = ({ showBottomSheet, setShowBottomSheet, setShow }) => {
  const { t } = useTranslation();
  const { paletteId } = useParams();
  const { colormatch, images, user } = useAppContext();
  const [progress, setProgress] = useState({ totalBytes: 0, complete: 0 });
  const { data: palette, isSuccess } = usePaletteById(paletteId, false, {
    enabled: !!paletteId,
  });
  const { mutate: deleteImagesFromStorage } = useDeleteUploadedImagesStorage();
  const updatePalette = useUpdatePalette();
  const [loading, setLoading] = useState(false);
  const [indexToDelete, setIndexToDelete] = useState([]);

  const convertURLToFile = async (imageURL, imageName) => {
    const response = await fetch(imageURL);
    const blob = await response.blob();
    const file = new File([blob], imageName, { type: blob.type });
    return file;
  };

  useEffect(() => {
    const paletteFiles = async () => {
      if (paletteId && isSuccess) {
        const dbImages = await Promise.all(
          palette.images.map(async (image) => {
            const { imageURL, imageName } = image;
            return await convertURLToFile(imageURL, imageName);
          })
        );

        const indexToDelete = dbImages
          .map((image1, index) =>
            !images.some(
              (image2) =>
                image1.name === image2.name && image1.size === image2.size
            )
              ? index
              : -1
          )
          .filter((index) => index !== -1);
        setIndexToDelete(indexToDelete);
      }
    };
    paletteFiles();
  }, [paletteId, palette, isSuccess, images]);

  const preparePaletteToSave = useCallback(
    async (paletteName, id) => {
      const imagesWithURLs = await imagesMapToUpload({
        images,
        setProgress,
        collection: "savedPalettes",
        id,
      });
      return {
        images: imagesWithURLs,
        colors: colormatch,
        user: user.email,
        name: paletteName,
        createdAt: serverTimestamp(),
      };
    },
    [colormatch, images, user?.email]
  );

  const handleEditPalette = useCallback(async () => {
    setLoading(true);
    try {
      if (!paletteId) return;

      const paletteToSave = await preparePaletteToSave(palette.name, paletteId);

      const imagePathsToDelete = ({ images }) => {
        if (Array.isArray(images)) {
          return images.map((image) => {
            return deleteImagesFromStorage({
              imagePath: image.imagePath,
              imageURL: image.imageURL,
            });
          });
        } else if (typeof images === "string") {
          return deleteImagesFromStorage({
            imageURL: images,
          });
        }
      };

      const imagesToDelete = indexToDelete.map(
        (index) => palette.images[index]
      );
      imagePathsToDelete({
        images: imagesToDelete,
      });
      updatePalette.mutate({
        palette: paletteToSave,
        paletteId: paletteId,
      });
      Alert.success(
        `Palette updated. View it under 'My Palettes' in your profile.`
      );
    } catch (error) {
      Alert.error(error.message || "An error occurred");
    } finally {
      setShowBottomSheet((prevState) => ({
        ...prevState,
        save: false,
      }));
      setLoading(false);
    }
  }, [
    deleteImagesFromStorage,
    preparePaletteToSave,
    indexToDelete,
    palette?.name,
    palette?.images,
    paletteId,
    updatePalette,
    setShowBottomSheet,
  ]);

  return (
    <BottomSheet
      open={showBottomSheet}
      setOpen={() =>
        setShowBottomSheet((prevState) => ({
          ...prevState,
          save: false,
        }))
      }
    >
      <ListGroup>
        <ListGroup.Item
          action
          className={style.mainOption}
          onClick={handleEditPalette}
        >
          {loading ? (
            <>
              {t("Saving")} <Spinner animation="border" size="sm" />
            </>
          ) : (
            `${t("Save Changes")}`
          )}
        </ListGroup.Item>
        <ListGroup.Item
          action
          className={style.mainOption}
          onClick={() => {
            setShow({ save: true });
            setShowBottomSheet((prevState) => ({
              ...prevState,
              save: false,
            }));
          }}
        >
          {t("Save as New...")}
        </ListGroup.Item>
      </ListGroup>
    </BottomSheet>
  );
};

export default SaveBottomSheet;
