import React from "react";
import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
  Font,
} from "@react-pdf/renderer";
import poppinsFont from "../../assets/Poppins-Medium.ttf";
import workSansFont from "../../assets/WorkSans-Regular.ttf";

Font.register({ family: "Poppins", src: poppinsFont });
Font.register({ family: "WorkSans", src: workSansFont });

const style = StyleSheet.create({
  page: {
    height: "auto",
    display: "flex",
    flexDirection: "column",
  },
  body: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "14px",
  },
  imageContainer: {
    height: "auto",
    width: "80%",
  },
  imagesContainer: {
    height: "auto",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "5px",
  },
  images: {
    height: "auto",
    width: "45%",
  },
  image: {
    height: "auto",
    width: "100%",
  },
  cardsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: "30px",
    marginTop: "18px",
    justifyContent: "flex-start",
  },
  cardsContainerPreference: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    /* justifyContent: "space-between", */
  },
  cards: {
    width: "calc(20% - 10px)",
    margin: "10px",
    boxSizing: "border-box",
  },
  cardsPreference: {
    width: "calc(26% - 20px)",
    marginHorizontal: "10px",
    marginBottom: "10px",
    boxSizing: "border-box",
  },
  title: {
    fontFamily: "WorkSans",
    textTransform: "uppercase",
    marginTop: "8px",
    fontSize: "8px",
    lineHeight: "1",
    wordBreak: "break-word",
    textAlign: "left",
  },
  titlePreference: {
    fontFamily: "WorkSans",
    textTransform: "uppercase",
    marginTop: "8px",
    fontSize: "8px",
    lineHeight: "1",
    wordBreak: "break-word",
    textAlign: "left",
    maxWidth: "80px",
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    margin: "10px",
  },
  cardBrand: {
    fontSize: "7px",
    color: "#A1A1A1",
    marginTop: "4px",
  },
});

const PDFClient = ({
  colors,
  images,
  showImage,
  sharedPalette,
  user,
  paletteName,
  exportOption,
}) => {
  return (
    <Document>
      <Page style={style.page} wrap={false}>
        <View>
          <Text
            style={{
              color: "#000000",
              fontSize: "18px",
              fontWeight: "600",
              fontFamily: "WorkSans",
              marginLeft: "14px",
              marginTop: "10px",
            }}
          >
            {paletteName && paletteName}
          </Text>
          <Text
            style={{
              color: "#686767",
              fontSize: "8px",
              fontFamily: "WorkSans",
              marginLeft: "14px",
            }}
          >
            Created by: {user.name}{" "}
            {user.company ? " - " + user.company + " " : null}{" "}
          </Text>
        </View>
        <View style={showImage && style.body}>
          {showImage && (
            <View
              style={
                images.length > 1 ? style.imagesContainer : style.imageContainer
              }
            >
              {images.map((img, index) => (
                <Image
                  key={index}
                  src={sharedPalette ? img.imageURL : img}
                  style={images.length > 1 ? style.images : style.image}
                />
              ))}
            </View>
          )}
          <View
            style={
              showImage ? style.cardsContainerPreference : style.cardsContainer
            }
          >
            {colors.length > 0 &&
              colors.map((card, index) => {
                return (
                  <View
                    key={index}
                    style={showImage ? style.cardsPreference : style.cards}
                  >
                    <View
                      style={{
                        width: "100%",
                        height: "10px",
                        backgroundColor: card.colorPicked
                          ? card.colorPicked
                          : card.hexColor.length === 1
                          ? "transparent"
                          : `#${card.hexColor.replace("#", "")}`,
                      }}
                    />
                    <Image
                      src={card.image ? card.image[0].imageURL : card.imageURL}
                      alt="balloon"
                      style={style.image}
                    />
                    <Text
                      style={showImage ? style.titlePreference : style.title}
                    >
                      {exportOption !== "internal" && card.displayName
                        ? card.displayName
                        : card.name}
                    </Text>

                    {exportOption === "internal" && !card.combined && (
                      <Text style={style.cardBrand}>
                        {card.displayName ? card.brand : <br />}
                      </Text>
                    )}

                    {exportOption === "internal" && card.combined && (
                      <>
                        <Text style={style.cardBrand}>OUTSIDE/INSIDE</Text>
                        <Text style={style.cardBrand}>
                          {card.brand.split("/")[0]} - {card.outsideColor} /{" "}
                          {card.brand.split("/")[1]} - {card.insideColor}
                        </Text>
                      </>
                    )}
                  </View>
                );
              })}
          </View>
        </View>
        <View style={style.footer}>
          <Text
            style={{
              color: "#c8c8c8",
              fontSize: "8px",
              fontWeight: "600",
              fontFamily: "WorkSans",
            }}
          >
            Powered by Balloon Color Match
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default PDFClient;