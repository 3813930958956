import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FiPlusCircle } from "react-icons/fi";
import { Button, Col, Row } from "react-bootstrap";
import { useAppContext } from "../../context";
import { DownloadBtn, SaveBtn, ShareBtn } from "../index";
import style from "./Commands.module.css";

const ActionCommands = () => {
  const { colormatch, images, setCreateNewPaletteModal } = useAppContext();
  const { t } = useTranslation();
  const [overlay, setOverlay] = useState({
    save: false,
    download: false,
    share: false,
  });

  const handleCreateNewPalette = () => {
    setCreateNewPaletteModal(true);
  };

  return (
    <Col lg={{ order: 0, span: 6 }} className={`${style.actionCommandsContainer}`}>
      <Row className={`p-0 m-0 jusify-content-xs-between ${style.cardsCommandsRow}`}>
        <Col xs="auto" className={`p-0 m-0 ${style.cardsCommandsCol}`}>
          <SaveBtn overlay={overlay.save} setOverlay={setOverlay} />
        </Col>
        <Col xs="auto" className={`p-0 m-0 ${style.cardsCommandsCol}`}>
          <ShareBtn overlay={overlay.share} setOverlay={setOverlay} share />
        </Col>
        <Col xs="auto" className={`p-0 m-0 ${style.cardsCommandsCol}`}>
          <DownloadBtn overlay={overlay.download} setOverlay={setOverlay} />
        </Col>
        <Col xs="auto" className={`p-0 m-0 ${style.cardsCommandsCol}`}>
          <Button
            variant="secondary"
            onClick={handleCreateNewPalette}
            size="sm"
            className={style.actionBtnCNP}
            disabled={colormatch.length === 0 && images.length === 0}
          >
            <FiPlusCircle />
            {t("Create New Palette")}
          </Button>
        </Col>
      </Row>
      {(overlay.share || overlay.save || overlay.download) && (
        <div className={style.overlay} />
      )}
    </Col>
  );
};

export default ActionCommands;
