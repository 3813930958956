import React, {useState, useEffect} from "react";
import { Button, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import style from "./Commands.module.css";
import { useTranslation } from "react-i18next";
import { RiDragMove2Fill } from "react-icons/ri";
import { useAppContext } from "../../context";
import { AddButton } from "../AddElement";
import { useUpdateFirstShowLibraryHelpTooltip } from "../../utils/Data/FirstToolVisit";

const CardsCommands = ({ sortable, setSortable }) => {
  const [showHelpLibraryTooltip, setShowHelpLibraryTooltip] = useState(false);
  const {colormatch, user, setUser} = useAppContext()
  const { t } = useTranslation();
  const updateFirstShowLibraryHelpTooltip = useUpdateFirstShowLibraryHelpTooltip();

  useEffect(() => {
    if (user) {
      if (user.firstShowLibraryHelpTooltip) {
        setShowHelpLibraryTooltip(true);
      }
    }
  }, [user]);
  
  const handleCloseHelpTooltip = () => {
    setShowHelpLibraryTooltip(false);
    setUser((prevState) => ({ ...prevState, firstShowLibraryHelpTooltip: false }));
    updateFirstShowLibraryHelpTooltip.mutate(user.email);
  };

  return (
    <Col lg={{ order: 0, span: 6 }} className={`mt-4 ${style.cardsCommandsContainer}`}>
      <Row className={`p-0 m-0 jusify-content-xs-between ${style.cardsCommandsRow}`}>
        <OverlayTrigger
          placement="bottom"
          show={user ? showHelpLibraryTooltip : false}
          flip={true}
          overlay={
            <Tooltip className={style.helpTooltip} id="help-tooltip">
              <Row className="d-flex align-items-center">
                <Col xs={8}>
                  {/* <p className={style.matchingTooltipTitle}>Find Balloon Match</p> */}
                  <p className={style.helpTooltipText}>{t("Browse 1,000+ balloon colors and add them to your palette!")}</p>
                </Col>
                <Col xs={4} className={`${style.helpTooltipBtnCol} d-flex justify-content-end`}>
                  <Button
                    className={style.helpTooltipBtn}
                    onClick={handleCloseHelpTooltip}
                  >
                    {/* {t("Got it")} */}
                    X
                  </Button>
                </Col>
              </Row>
            </Tooltip>
          }
        >
          <Col xs="auto" className={`p-0 ${style.actionBtnColumn}`}>
            <AddButton />
          </Col>
        </OverlayTrigger>
        <Col xs="auto" className={`p-0 ${style.actionBtnColumn}`}>
          <Button
            className={`${style.cardsCommandsActionBtn} ${sortable && style.blackBtn}`}
            onClick={() => setSortable(!sortable)}
            variant="secondary"
            disabled={colormatch.length > 1 ? false : true}
          >
            {sortable ? null : <RiDragMove2Fill />}
            {sortable ? <span>{t("Done")}</span> : <span> {t("Reorder")}</span>}
          </Button>
        </Col>
      </Row>
    </Col>
  )
}

export default CardsCommands;