import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import style from "./Filters.module.css";
import { ViewDetailsRow } from "../CardsContainer";

const SizeOption = ({ size }) => {
  const formattedSize = size.toString().replace(/\./g, "_");
  const baseSize = formattedSize.split("_")[0];
  const sizeLabel = size.toString().includes(".")
    ? `${baseSize}" LINK`
    : size > 100
    ? `${baseSize}`
    : `${size}"`;

  return (
    <Col xs={"auto"}>
      <small className={style.rowData}>{sizeLabel}</small>
    </Col>
  );
};

const BalloonOption = memo(({ option, sizes }) => {
  const { t } = useTranslation();
  return (
    <>
      <ViewDetailsRow sizes={sizes} item={option} autocomplete={true} />
    </>
    
  );
});

export default BalloonOption;
