import React from "react";
import { Button, Col, Form, OverlayTrigger, Popover, Row } from "react-bootstrap";
import { FiSun, FiTrash2 } from "react-icons/fi";
import { GrRefresh } from "react-icons/gr";
import style from "./MobileTopBarCommands.module.css";
import { RiImageAddFill } from "react-icons/ri";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { DropZone } from "../../DropZone";
import { useScreenSize } from "../../../utils";
import { useTranslation } from "react-i18next";

const MobileTopBarCommands = ({currentBrightness, images, handleBrightnessChange, goToPrev, goToNext, index, handleDeleteImage}) => {
  const screenSize = useScreenSize();
  const {t} = useTranslation();

  const popover = (
    <Popover id="popover-brightness">
      <Popover.Body>
        <Row className={`p-0 m-0 justify-content-between align-items-center`}>
          <Col className="p-0" xs={1}>
            <FiSun />
          </Col>
          <Col xs={6} lg={5} className="p-0">
            <Form.Range
              type="range"
              min="0.5"
              max="1.5"
              step="0.01"
              className={style.rangeBar}
              value={currentBrightness()}
              onChange={(e) => handleBrightnessChange(Number(e.target.value))}
            />
          </Col>
          <Col className="p-0" xs="auto">
            <FiSun size="1.5rem" />
          </Col>
          <Col className="p-0" xs="auto">
            <Button
              variant="secondary"
              size="sm"
              onClick={() => handleBrightnessChange(1)}
              className={style.actionBtn}
            >
              <GrRefresh />
            </Button>
          </Col>
        </Row>
      </Popover.Body>
    </Popover>
  );
  return (
    <>
      <Row className={style.mobileTopBarCommandsContainer}>
        <Col>
          <div className={`text-center ${style.mobileTopBarAddBtn}`}>
            <RiImageAddFill size="1.2rem" />
            <DropZone addTopBarMobile={true} />
          </div>
        </Col>
        {images.length > 1 && screenSize <= 992 && (
          <Col className={`p-0 ${style.prevNextContainer}`}>
            <Button
              variant="dark"
              onClick={goToPrev}
              disabled={index === 0}
              size="sm"
              className={style.mobileTopBarCommandsBtn}
            >
              <FaArrowLeft />
            </Button>
            {`${index +1}/${images.length}`} 
            <Button
              variant="dark"
              onClick={goToNext}
              disabled={images.length - 1 === index}
              size="sm"
              className={style.mobileTopBarCommandsBtn}
            >
              <FaArrowRight />
            </Button>
          </Col>
        )}
          <Col>
            <div className="text-center">
              <Button
                onClick={handleDeleteImage}
                disabled={images.length > 0 ? false : true}
                className={style.mobileTopBarCommandsBtn}
              >
                <FiTrash2 size="1.2rem" />
                <div>
                  {t("Delete")}
                </div>
              </Button>
            </div>
          </Col>
          <Col>
            <OverlayTrigger
              trigger="click"
              placement="bottom"
              overlay={popover}
              rootClose
            >
              <Button
                disabled={images.length > 0 ? false : true}
                className={style.mobileTopBarCommandsBtn}
              >
                <FiSun size="1.2rem" />
                <div>
                  {t("Brightness")}
                </div>
              </Button>
            </OverlayTrigger>
          </Col>
      </Row>
    </>
  )
}

export default MobileTopBarCommands;