import React from "react";
import { Button, Col } from "react-bootstrap";
import { TiDelete } from "react-icons/ti";
import style from "../Form.module.css";

const SelectedOption = ({ id, displayName, name, selected, setSelected }) => {
  const handleDelete = () => {
    setSelected(selected.filter((option) => option.id !== id));
  };

  return (
    <Col xs="auto" className={style.selectedOption}>
      {displayName} - {name}{" "}
      <Button className={style.deleteBtn} onClick={handleDelete}>
        <TiDelete />
      </Button>
    </Col>
  );
};

export default SelectedOption;
